import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { Account, Permission, Role } from 'appwrite';
import client from './app-user';

// Define a type for the user session
// Replace 'any' with specific properties if known
interface UserSession {
  id: string;
  email: string;
  name: string;
  labels: string[];
  role?: string;
}

// Define a type for the user state
interface UserState {
  session: UserSession | null;
}

// Define a type for the context state
interface AuthContextType {
  user: UserState;
  setUser: React.Dispatch<React.SetStateAction<UserState>>;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  checkSession: () => Promise<void>; // Add this line
}

// Initialize the context with a default value
export const AuthContext = createContext<AuthContextType>({
  user: { session: null },
  setUser: () => {},
  login: async () => {},
  logout: async () => {},
  checkSession: async () => {},
});

export const AuthProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [user, setUser] = useState<UserState>({ session: null });

  const account = new Account(client);

  const fetchUserDetails = async (): Promise<UserSession | null> => {
    try {
      const result = await account.get();
      
      if (result) {
        const userDetails: UserSession = {
          id: result.$id,
          email: result.email,
          name: result.name,
          labels: result.labels
          // role: result.labels && result.labels.includes('admin') ? 'admin' : 'user',
        };
        return userDetails;
      }
      return null;  // Ensure that all paths return a value
    } catch (error) {
      console.error('Error fetching user details:', error);
      return null;
    }
  };
  
  
  const checkSession = async () => {
    const userDetails = await fetchUserDetails();
    if (userDetails) {
      setUser({ session: userDetails });
    } else {
      setUser({ session: null });
    }
  };
  
  const login = async (email: string, password: string) => {
    try {
      await account.createEmailPasswordSession(email, password);
      await checkSession();
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };
  

  const logout = async () => {
    try {
      console.log('logout called...');
      setUser({ session: null });
      await account.deleteSession('current'); // Delete the current session for logout
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  useEffect(() => {
    checkSession();
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, login, logout, checkSession }}>
      {children}
    </AuthContext.Provider>
  );
};
