import React, { useState, useContext, useEffect } from 'react'
import { Account, Client } from 'appwrite';
import { AuthContext } from '../../auth-context';
import { Link } from 'react-router-dom'

import * as FaIcons from 'react-icons/fa' 

import { SidebarNavData } from './SidebarNavData'
import styles from './SidebarNav.module.css';

import styled from 'styled-components'

const Navbar = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    height:  3.5rem;
    background-color: #040d1a;
`

const MenuIconOpen = styled(Link)`
    display: flex;
    justify-content: start;
    font-size: 1.5rem;
    margin-left: 2rem;
    color: #ffffff;
`

const MenuIconClose = styled(Link)`
    display: flex;
    justify-content: end;
    font-size: 1.5rem;
    margin-top: 0.75rem;
    margin-right: 1rem;
    color: #ffffff;
`

const SidebarMenu = styled.div<{close: boolean}>`
    width: 236px;
    height: 100vh;
    background-color: #25333a;
    position: fixed;
    top: 0;
    left: ${({ close}) => close ? '0' : '-100%'};
    transition: .6s;
    z-index: 1;
`

const MenuItems = styled.li`
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    height: 20px;
    padding: 1rem 0 1.25rem;
`

const MenuItemLinks = styled(Link)`
        display: flex;
        align-items: center;
        padding: 0 2rem;
        font-size: 16px;
        text-decoration: none;
        color: #F5F7FA;
        position: relative; /* Needed for pseudo-element positioning */
        overflow: visible; /* Allows pseudo-element to visually extend beyond the component */
        height: 120%;

    &::after {
        content: '';
        position: absolute;
        top: -33%; /* Extend upwards */
        left: 0;
        right: 0;
        bottom: -33%; /* Extend downwards */
        background-color: #15212b;
        border-radius: 5px;
        z-index: -1;
        opacity: 0;
        width: 85%;
        margin-left: 5%;
        transition: opacity 0.4s ease;
    }

    &:hover::after {
        opacity: 1; /* Reveal the background on hover */
        width: 85%;
        margin-left: 5%;
    }
`

const SidebarNav: React.FunctionComponent = () => {
    
    const { user } = useContext(AuthContext);
    const [close, setClose] = useState(false);
    const showSidebar = () => setClose(!close);

    // Check if user is admin
    const isAdmin = user.session?.labels.includes('admin');

    return (
        <>
            <Navbar>
                <MenuIconOpen to="#" onClick={showSidebar}>
                <svg width="24" height="24" viewBox="0 0 448 512" fill="white" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h312c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h208c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z" />
                </svg>
            </MenuIconOpen>
            </Navbar>

            <SidebarMenu close={close}>
                <MenuIconClose to="#" onClick={showSidebar}>
                    <FaIcons.FaTimes />
                </MenuIconClose>

                {SidebarNavData.filter(item => !item.adminOnly || isAdmin).map((item, index) => (
                    <MenuItems key={index}>
                        <MenuItemLinks to={item.path}>
                            {item.icon}
                            <span style={{ marginLeft: '16px' }}>{item.title}</span>
                        </MenuItemLinks>
                    </MenuItems>
                ))}
            </SidebarMenu>
       </>
   )
}
export default SidebarNav
