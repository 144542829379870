import { Client, Account, ID } from 'appwrite';

const client = new Client();

// Define a helper function to check and log if default values are being used
function getEnvVariable(name: string, defaultValue: string) {
  const value = process.env[name];
  if (value === undefined) {
    console.warn(`Environment variable ${name} is not set. Using default value: ${defaultValue}`);
    return defaultValue;
  }
  return value;
}

client
.setEndpoint(getEnvVariable('REACT_APP_ENDPOINT', 'endpoint'))
.setProject(getEnvVariable('REACT_APP_PROJECT_ID', 'project'));
// .setKey(process.env.APPWRITE_FUNCTION_API_KEY)
// .setSelfSigned(true);
;

const account = new Account(client);

// Function to create a user account
export const createUser = async (email: string, password: string) => {
    try {
      const response = await account.create('unique()', email, password);
      console.log(response);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  
  // Function to create an email session (login)
  export const createEmailSession = async (email: string, password: string) => {
    try {
      const response = await account.createSession(email, password);
      console.log(response);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  
  export default client;