import React from 'react'
import styled from 'styled-components'
import styles from '../styles/free-agency.module.css';

const FreeAgency: React.FunctionComponent = () => {
    return (
        <div className={styles.container}><h1>Free Agency Coming Soon...</h1></div>
    )
}

export default FreeAgency
