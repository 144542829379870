import React from 'react';
import styled from 'styled-components';
import { Client, Databases, Query, ID } from 'appwrite';
import styles from '../styles/admin.module.css';

const client = new Client();
const databases = new Databases(client);

// Define a helper function to check and log if default values are being used
function getEnvVariable(name: string, defaultValue: string) {
  const value = process.env[name];
  if (value === undefined) {
    console.warn(`Environment variable ${name} is not set. Using default value: ${defaultValue}`);
    return defaultValue;
  }
  return value;
}

// Use your existing helper function to get environment variables
const DATABASE_ID = getEnvVariable('REACT_APP_DATABASE_ID', 'database');
const ROSTERED_PLAYER_DATA_COLLECTION_ID = getEnvVariable('REACT_APP_ROSTERED_PLAYER_DATA_COLLECTION_ID', 'roster data');

// Initialize Appwrite client
client
  .setEndpoint(getEnvVariable('REACT_APP_ENDPOINT', 'endpoint'))
  .setProject(getEnvVariable('REACT_APP_PROJECT_ID', 'project'));

interface PlayerDocument {
  roster_id: string;
  roster_status: 'starter' | 'bench' | 'reserve' | 'taxi'; // Adjust as needed
  owner: string;
  team_name: string;
  playerId: string;
  name: string;
  first_name: string;
  last_name: string;
  position: string;
  team: string;
  college: string;
  number: number;
  age: number;
  fantasy_positions: string[]; // Adjust the type as needed
  salary: number;
  duration: number;
  contract_type: string;
}

const reduceContractDuration = async () => {
  try {
    let allDocuments: PlayerDocument[] = [];
    let limit = 50;
    let offset = 0;

    // Fetch all documents from the Rostered Player collection
    while (true) {
      const result = await databases.listDocuments(
        DATABASE_ID,
        ROSTERED_PLAYER_DATA_COLLECTION_ID,
        [Query.limit(limit), Query.offset(offset)]
      );

      const documents = result.documents as unknown as PlayerDocument[];

      allDocuments = [...allDocuments, ...documents];

      if (documents.length < limit) {
        break;
      }

      offset += limit;
    }

    // Reduce duration for each document
    const updatePromises = allDocuments.map(async (doc) => {
      const newDuration = Math.max(0, doc.duration - 1); // Ensure duration doesn't go below 0
      return databases.updateDocument(
        DATABASE_ID,
        ROSTERED_PLAYER_DATA_COLLECTION_ID,
        doc.playerId,
        { duration: newDuration }
      );
    });

    await Promise.all(updatePromises);
    alert('Contract durations reduced successfully');
  } catch (error) {
    console.error('Error reducing contract durations:', error);
    alert('Failed to reduce contract durations');
  }
};

const Admin: React.FunctionComponent = () => {
  return (
    <div className={styles.container}>
      <h1>Admin Area</h1>
      <div className={styles.subcontainer}>
        <button onClick={reduceContractDuration}>Reduce Contract Duration</button>
        <p>Refresh player database</p>
        <p>Reduce contract duration</p>
        <p>Mark resign false</p>
        <p>Show / Hide RFA</p>
        <p>Show / Hide Free Agency</p>
      </div>
    </div>
  );
};

export default Admin;
