import React, { useEffect, FunctionComponent, useState, useContext } from 'react';
import { Account, Client } from 'appwrite';  // Import the Client class from the Appwrite SDK
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider, AuthContext } from './auth-context';
import { LoginModal } from './login';

import Sidebar from './components/SidebarNav';  // Import the Sidebar component

import Home from './pages/home';
import TeamSheets from './pages/sheets';
import RFA from './pages/rfa';
import FreeAgency from './pages/free-agency';
import Admin from './pages/admin';

const App: FunctionComponent = () => {

  const client = new Client();

  // Define a helper function to check and log if default values are being used
  function getEnvVariable(name: string, defaultValue: string) {
    const value = process.env[name];
    if (value === undefined) {
      console.warn(`Environment variable ${name} is not set. Using default value: ${defaultValue}`);
      return defaultValue;
    }
    return value;
  }
  
  // Use the helper function to initialize your constants
  const DATABASE_ID = getEnvVariable('REACT_APP_DATABASE_ID', 'database');
  const ROSTERED_PLAYER_DATA_COLLECTION_ID = getEnvVariable('REACT_APP_ROSTERED_PLAYER_DATA_COLLECTION_ID', 'roster data');
  const DEAD_CAP_DATA_COLLECTION_ID = getEnvVariable('REACT_APP_DEAD_CAP_DATA_COLLECTION_ID', 'dead cap');

  client
    .setEndpoint(getEnvVariable('REACT_APP_ENDPOINT', 'endpoint'))
    .setProject(getEnvVariable('REACT_APP_PROJECT_ID', 'project'))
    // .setKey(process.env.APPWRITE_FUNCTION_API_KEY)
    // .setSelfSigned(true);
    ;

  const account = new Account(client);

  const { user, checkSession, logout } = useContext(AuthContext);
  const [showLogin, setShowLogin] = useState(false);  
  
  const handleLoginClose = () => setShowLogin(false);

  const handleSignOut = () => {
    logout();
    checkSession();
    window.location.reload()
  };

  return (
    <>
        {showLogin && <LoginModal onClose={handleLoginClose} />}
          <div className={`main-content ${showLogin ? 'blurred' : ''}`}>
            <div className={'container'}>
                <div className={'login'}>
                {!user.session ? (
                        <button className={'button'} onClick={() => setShowLogin(true)}>Sign In</button>
                    ) : (
                        <button className={'button'} onClick={handleSignOut}>Sign Out</button>
                    )}
                </div>
            </div>
            <Router>
              <Sidebar />
              <Routes>
                <Route path='/' element={<Home />} />   
                <Route path='/sheets' element={<TeamSheets />} />            
                <Route path='/rfa' element={<RFA />} />    
                <Route path='/free-agency' element={<FreeAgency />} />
                <Route path='/admin' element={<Admin />} />
              </Routes>
            </Router> 
          </div>
    </>
    );
  };

export default App;
