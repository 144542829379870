import React from 'react'

export const SidebarNavData = [
    {
        title: 'Home',
        path: '/',
        icon: <img src="/icons/home-icon.svg" alt="Home" width="10%" text-align="center" />,
        adminOnly: false

    },
    {
        title: 'Team Sheets',
        path: '/sheets',
        icon: <img src="/icons/jersey-icon.svg" alt="Team Sheets" width="10%" text-align="center" />,
        adminOnly: false
    },
    {
        title: 'Resigning FAs',
        path: '/rfa',
        icon: <img src="/icons/contract-icon.svg" alt="Resigning" width="10%" text-align="center" />,
        adminOnly: false
    },
    {
        title: 'Free Agency',
        path: '/free-agency',
        icon: <img src="/icons/gavel-icon.svg" alt="Free Agency" width="10%" text-align="center" />,
        adminOnly: false
    },
    {
        title: 'Admin',
        path: '/admin',
        icon: <img src="/icons/admin-icon.svg" alt="Admin" width="10%" text-align="center" />,
        adminOnly: true
    }
]
