import React, { useState, useEffect, useContext } from 'react';
import { Client, Databases, Query, ID, Account } from "appwrite";
import { AuthProvider, AuthContext } from '../auth-context';
import styles from '../styles/rfa.module.css';

// Define a helper function to check and log if default values are being used
function getEnvVariable(name: string, defaultValue: string) {
  const value = process.env[name];
  if (value === undefined) {
    console.warn(`Environment variable ${name} is not set. Using default value: ${defaultValue}`);
    return defaultValue;
  }
  return value;
}

// Use the helper function to initialize your constants
const DATABASE_ID = getEnvVariable('REACT_APP_DATABASE_ID', 'database');
const ROSTERED_PLAYER_DATA_COLLECTION_ID = getEnvVariable('REACT_APP_ROSTERED_PLAYER_DATA_COLLECTION_ID', 'roster data');
const DEAD_CAP_DATA_COLLECTION_ID = getEnvVariable('REACT_APP_DEAD_CAP_DATA_COLLECTION_ID', 'dead cap');
const RFA_BID_COLLECTION_ID = getEnvVariable('REACT_APP_RFA_BIDS_COLLECTION_ID', 'RFA bids');

// Initialize Appwrite Client
const client = new Client();
client
.setEndpoint(getEnvVariable('REACT_APP_ENDPOINT', 'endpoint'))
.setProject(getEnvVariable('REACT_APP_PROJECT_ID', 'project'));

const databases = new Databases(client);
const account = new Account(client);

interface Player {
  salary: number;
  duration: number;
  roster_id: string;
  name: string;
  position: string;
  playerId: string;
  resign: boolean;
}

const RFAPage = () => {
  const [players, setPlayers] = useState<Player[]>([]);
  const [submittingPlayer, setSubmittingPlayer] = useState<string | null>(null);
  const [submittedPlayers, setSubmittedPlayers] = useState<Set<string>>(new Set());
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true); // Start loading
    fetchPlayers();
  }, []);

  const fetchPlayers = async () => {
    try {
      let players: Player[] = [];
      let limit = 50;
      let offset = 0;
      let total = 0;
      let totalFetched = 0;
      let totalPlayers = 0;
  
      // Continue fetching until no more documents are available
      while (true) {
        let result = await databases.listDocuments(
          DATABASE_ID,
          ROSTERED_PLAYER_DATA_COLLECTION_ID,
          [Query.limit(limit), Query.offset(offset)]
        );

        //  console.log(`Fetched batch: ${result.documents.length} documents`);
  
        // Filter out players based on your specific condition
        let filtered = result.documents.filter(doc => doc.restricted_free_agent === true);
        players = players.concat(filtered.map(doc => ({
          salary: doc.salary,
          duration: doc.duration,
          roster_id: doc.roster_id,
          name: doc.name,
          position: doc.position,
          playerId: doc.playerId,
          resign: doc.restricted_free_agent
        })));

        // console.log(`Filtered batch: ${filtered.length} players`);
  
        // Update totals
        totalFetched += result.documents.length;
        totalPlayers += filtered.length;
  
        // Update total and check if there are still documents to fetch
        total += result.documents.length;
        if (result.documents.length < limit) {
          break; // Exit the loop if fewer documents than limit are returned or no documents meet the criteria
        }
  
        offset += limit; // Increase offset to fetch the next batch
      }
      
      setPlayers(players); // Update state with all filtered players
      setIsLoading(false); // End loading after data is fetched
    } catch (error) {
      console.error("Error fetching RFA players:", error);
      setIsLoading(false); // End loading even if there's an error
    }
  };
  
  if (isLoading) {
    return (
      <div className={styles.loading_container}>
        <div className={styles.ring}>Loading
        <span></span>
        </div>
      </div>
    );
  }

  const submitBid = async (playerId: string, bidAmount: number, bidDuration: number) => {
    try {
      if (!user.session) {
        alert('You must be logged in to submit a bid.');
        return;
      }

      setSubmittingPlayer(playerId); // testing checkmark

      // Get the player data to include in the bid document
      const playerData = players.find(player => player.playerId === playerId);

      if (!playerData) {
        console.error('No player found with ID:', playerId);
        // Optionally, you can also notify the user through UI elements
        setSubmittingPlayer(null);
        return;  // Exit the function if no player data is found
      }

      const userDetails = await account.get();

      const bidDocument = {
        playerId: playerId,
        bidAmount: bidAmount,
        bidDuration: bidDuration,
        playerName: playerData.name,
        playerPosition: playerData.position,
        owner: userDetails.name
        // You can add more fields from the player or other related info here
      };

      // Submit the bid to the bids table in Appwrite
      await databases.createDocument(DATABASE_ID, RFA_BID_COLLECTION_ID, ID.unique(), bidDocument);
      // console.log(`Submitting bid for player ${playerId}: $${bidAmount} for ${bidDuration} years, by ${userDetails.name}.`);
      
      setSubmittingPlayer(null);
      setSubmittedPlayers(prev => new Set(prev).add(playerId));
    } catch (error) {
    // console.error("Error submitting bid:", error);
    // console.error("Database ID:", DATABASE_ID);
    // console.error("Collection ID:", RFA_BID_COLLECTION_ID);
    }
  };

  const handleBidSubmit = (player: Player) => (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget as HTMLFormElement;
  
    const bidAmountInput = form.elements.namedItem('bidAmount') as HTMLInputElement;
    const bidDurationInput = form.elements.namedItem('bidDuration') as HTMLInputElement;
  
    // Convert values from string to the appropriate types
    const bidAmount = parseFloat(bidAmountInput.value);
    const bidDuration = parseInt(bidDurationInput.value, 10);
  
    // Call the submitBid function with these values
    submitBid(player.playerId, bidAmount, bidDuration);
  
    // Reset the form after submission
    form.reset();
  };

  return (
    <div className={styles.container}>
      <h1>Restricted Free Agency</h1>
      <p>Place your bids, you can make as many on you like on whoever you line, but keep in mind two things:<br/>
      <strong>1.</strong> Only the most recent bid on a player counts.<br/>
      <strong>2.</strong> Do not bid more, <strong>TOTAL</strong>, than you have in open cap space.</p>

      <ul className={styles.playerList}>
        {players.map((player) => (
          <li key={player.playerId} className={styles.playerItem}>
            <span><img src={`https://sleepercdn.com/content/nfl/players/thumb/${player.playerId}.jpg`} className={styles.playerPic}/> {player.name} - {player.position}</span>
            <form onSubmit={handleBidSubmit(player)} className={styles.bidForm}>
              <input
                name="bidAmount"
                type="number"
                placeholder="Bid Amount ($)"
                min="0"
                max="100"
                required
              />
              <input
                name="bidDuration"
                type="number"
                placeholder="Duration (Years)"
                min="1"
                max="5"
                required
              />
              <button type="submit" disabled={submittingPlayer === player.playerId || submittedPlayers.has(player.playerId)}>
                {submittingPlayer === player.playerId ? (
                  <div className={styles.spiral}></div>
                ) : submittedPlayers.has(player.playerId) ? (
                  <div className={styles.checkmark}></div>
                ) : (
                  'Submit Bid'
                )}
              </button>
            </form>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default RFAPage;
