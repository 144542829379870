import React, { useState, useContext } from 'react';
import { Client, Account, ID } from 'appwrite';
import { AuthContext } from './auth-context';
import './App.css';

interface LoginModalProps {
    onClose: () => void;
  }

  export const LoginModal: React.FC<LoginModalProps> = ({ onClose }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // Add this line
    const { login } = useContext(AuthContext);
  
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setErrorMessage(''); // Clear previous error messages on a new submit
      try {
        await login(email, password);
        onClose(); // Close the modal on successful login
      } catch (error) {
        
        console.error('Login failed:', error);
        setErrorMessage('That ain\'t it. Check your email and password and try again.');
      }
    };
  
    return (
      <div className="login-modal">
        <div className="login-content">
          <div className="close" onClick={onClose}>&times;</div>
          <img src={`${process.env.PUBLIC_URL}/logo.svg`} className='login-logo' alt="Logo" />
          <h3 className='login-header'>Please login</h3>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <form onSubmit={handleSubmit} className="form-login">
            <input
              className="form-field"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className="form-field"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button className='login-submit' type="submit">Login</button>
          </form>
        </div>
      </div>
    );
  };
  
