import React, { useState, useEffect } from 'react';
import { Client, Databases, Query } from "appwrite";
import styles from '../styles/sheets.module.css';

const client = new Client();

const databases = new Databases(client);

// Define a helper function to check and log if default values are being used
function getEnvVariable(name: string, defaultValue: string) {
  const value = process.env[name];
  if (value === undefined) {
    console.warn(`Environment variable ${name} is not set. Using default value: ${defaultValue}`);
    return defaultValue;
  }
  return value;
}

// Use the helper function to initialize your constants
const DATABASE_ID = getEnvVariable('REACT_APP_DATABASE_ID', 'database');
const ROSTERED_PLAYER_DATA_COLLECTION_ID = getEnvVariable('REACT_APP_ROSTERED_PLAYER_DATA_COLLECTION_ID', 'roster data');
const DEAD_CAP_DATA_COLLECTION_ID = getEnvVariable('REACT_APP_DEAD_CAP_DATA_COLLECTION_ID', 'dead cap');

client
  .setEndpoint(getEnvVariable('REACT_APP_ENDPOINT', 'endpoint'))
  .setProject(getEnvVariable('REACT_APP_PROJECT_ID', 'project'));

interface PlayerDocument {
  roster_id: string;
  roster_status: 'starter' | 'bench' | 'reserve' | 'taxi'; // Adjust as needed
  owner: string;
  team_name: string;
  playerId: string;
  name: string;
  first_name: string;
  last_name: string;
  position: string;
  team: string;
  college: string;
  number: number;
  age: number;
  fantasy_positions: string[]; // Adjust the type as needed
  salary: number;
  duration: number;
  contract_type: string;
}

interface CapPenaltyDocument {
  salary: number;
  duration: number;
  roster_id: string;
  name: string;
  playerId: string;
}

// Define TypeScript interfaces
interface Player {
  playerId: string;
  name: string;
  position: string;
  fantasy_positions: string[];
  team: string;
  number: number;
  status: 'starter' | 'bench' | 'reserve' | 'taxi';
  salary: number;
  duration: number;
  contract_type: string;
}

interface CutPlayer {
  salary: number;
  duration: number;
  roster_id: string;
  name: string;
  playerId: string;
}

interface Team {
  team_name: string;
  roster: Player[];
  cutPlayer: CutPlayer[];
}

const salaryCap = 182;

// Your Appwrite API calls would go here
const fetchData = async () => {
  try {
    let allDocuments: PlayerDocument[] = [];
    let capDocuments: CapPenaltyDocument[] = [];

    let limit = 50;
    let offset = 0;

    while (true) {
      const result = await databases.listDocuments(
        DATABASE_ID, 
        ROSTERED_PLAYER_DATA_COLLECTION_ID, 
        [Query.limit(limit),
          Query.offset(offset)],
      );

      const documents = result.documents as unknown as PlayerDocument[];

      allDocuments = allDocuments.concat(documents);

      if (result.documents.length < limit) {
        // Break the loop if the number of documents is less than the limit,
        // indicating that we've reached the end of the collection
        break;
      }

      offset += limit;
    }

    offset = 0; // Reset offset for dead cap data fetching

    while (true) {
      const result = await databases.listDocuments(
        DATABASE_ID, 
        DEAD_CAP_DATA_COLLECTION_ID, 
        [Query.limit(limit),
          Query.offset(offset)],
      );

      const documents = result.documents as unknown as CapPenaltyDocument[];

      capDocuments = capDocuments.concat(documents);

      if (result.documents.length < limit) {
        // Break the loop if the number of documents is less than the limit,
        // indicating that we've reached the end of the collection
        break;
      }

      offset += limit;
    }

    const playerDocuments = allDocuments as unknown as PlayerDocument[]
    const deadCapDocuments = capDocuments as unknown as CapPenaltyDocument[]

    // Using TypeScript's Record type for the accumulator
    const teams = playerDocuments.reduce<Record<string, Team>>((acc, playerDoc) => {
      const teamId = playerDoc.roster_id;

      if (!acc[teamId]) {
        acc[teamId] = {
          team_name: playerDoc.owner,
          roster: [],
          cutPlayer: []
        };
      }

      const player: Player = {
        playerId: playerDoc.playerId,
        name: playerDoc.name,
        position: playerDoc.position,
        fantasy_positions: playerDoc.fantasy_positions,
        team: playerDoc.team,
        number: playerDoc.number,
        status: playerDoc.roster_status,
        salary: playerDoc.salary,
        duration: playerDoc.duration,
        contract_type: playerDoc.contract_type
      };

      acc[teamId].roster.push(player);

      return acc;
    }, {});

    // Add dead cap data to teams
    capDocuments.forEach(capDoc => {
      const teamId = capDoc.roster_id;
      if (teams[teamId]) {
        const deadCapPlayer: CutPlayer = {
          playerId: capDoc.playerId,
          roster_id: capDoc.roster_id,
          name: capDoc.name,
          salary: capDoc.salary,
          duration: capDoc.duration
        };
        teams[teamId].cutPlayer.push(deadCapPlayer);
      }
    });

    return Object.values(teams);
  } catch (error) {
    console.error('Error fetching data:', error);
    return []; // Return an empty array in case of error
  }
};

function calculateTotalSalaryForTeam(team: Team): number {
  let totalSalary = 0;

  // Sum up salary from roster
  team.roster.forEach(player => {
    totalSalary += player.salary;
  });

  // Sum up salary from cut players
  team.cutPlayer.forEach(cutPlayer => {
    totalSalary += cutPlayer.salary;
  });

  return totalSalary;
}

// Main component
const TeamRosters: React.FC = () => {
  const [teams, setTeams] = useState<Team[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchAndSetData = async () => {
      setIsLoading(true); // Start loading
      const data = await fetchData();
      setTeams(data);
      setIsLoading(false); // End loading
    };
    
    fetchAndSetData();
  }, []);

  // Loading indicator rendering
  if (isLoading) {
    return (
      <div className={styles.loading_container}>
        <div className={styles.ring}>Loading
        <span></span>
        </div>
      </div>
    );
  }

  const renderRosterSection = (team: Team, status: Player['status']) => {

    const positionOrder = ['QB', 'RB', 'WR', 'TE', 'NT', 'DT', 'DE', 'DL', 'LB', 'CB', 'FS', 'SS', 'DB', 'K'];

    const players = team.roster.filter(player => player.status === status);
        players.sort((a, b) => {
        let posA = positionOrder.indexOf(a.position);
        let posB = positionOrder.indexOf(b.position);

        if (posA < posB) {
          return -1;
        }
        if (posA > posB) {
          return 1;
        }
        return 0;
      });

    return players.map((player, index) => (
      <div key={index}><div className={styles.playerPicContainer}><img src={`https://sleepercdn.com/content/nfl/players/thumb/${player.playerId}.jpg`} className={styles.playerPic}/></div><div className={styles.position}>{player.position}</div> <div><span className={styles.name}>{player.name}</span> <span className={styles.conract}>${player.salary} for {player.duration} years</span> <span className={styles.type}>{player.contract_type}</span></div>
      <div className={styles.subPlayerInfo}>{player.number} - {player.team}</div></div>
    ));
  };
  
  const renderDeadCapSection = (team: Team) => {

    const players = team.cutPlayer;

    return players.map((player, index) => (
      <div key={index}><div className={styles.playerPicContainer}><img src={`https://sleepercdn.com/content/nfl/players/thumb/${player.playerId}.jpg`} className={styles.playerPic}/></div><div><span className={styles.name}>{player.name}</span> <span className={styles.conract}>${player.salary} for {player.duration} years</span></div>
      <div className={styles.deadCapSpacer}>&nbsp;</div>
      </div>
    ));
  };

  const selectedTeamData = teams.find(t => t.team_name === selectedTeam);
  
  // Calculate the total salary for the selected team
  const totalSalaryRemaining = selectedTeamData ? salaryCap - calculateTotalSalaryForTeam(selectedTeamData) : 0;

  return (
    <div className={styles.background}>
      <div className={styles.tabs}>
        {teams.map((team, index) => {
              
              return (
          <button key={index}
                  onClick={() => setSelectedTeam(team.team_name)}
                  className={`${styles.button} ${team.team_name === selectedTeam ? styles.activeButton : ''}`}>
            {team.team_name}
          </button>
              );
              })}
      </div>

              {selectedTeamData && (
                <div className={styles.body}>
                  <div className={styles.name_banner}>
                    <h1 className={styles.team_name}>{selectedTeam}'s Team</h1>
                  </div>
                  <div className={styles.cap_banner}>
                    <h2 className={styles.cap_figure}>${totalSalaryRemaining}</h2>
                    <p>remaining</p>
                  </div>
                    <div className={styles.flex_container}>
                      <div className={styles.left_col}>
                      <div className={styles.shadow_box}>
                        <h3 className={styles.contactContext}>Contract Terms &amp; Guarantee</h3>
                      <h2 className={styles.section_header}>Starters</h2>
                        {renderRosterSection(selectedTeamData, 'starter')}</div>
                        <div className={styles.shadow_box}>
                        <h3 className={styles.contactContext}>Contract Terms &amp; Guarantee</h3>
                        <h2 className={styles.section_header}>Taxi Squad</h2>
                        {renderRosterSection(selectedTeamData, 'taxi')}</div>
                        <div className={styles.shadow_box}>
                        <h3 className={styles.contactContext}>Contract Terms &amp; Guarantee</h3>
                        <h2 className={styles.section_header}>Injured Reserve</h2>
                        {renderRosterSection(selectedTeamData, 'reserve')}</div>
                      </div>
                      <div className={styles.right_col}>
                      <div className={styles.shadow_box}>
                        <h3 className={styles.contactContext}>Contract Terms &amp; Guarantee</h3>
                        <h2 className={styles.section_header}>Bench</h2>
                        {renderRosterSection(selectedTeamData, 'bench')}</div>
                      <div className={styles.shadow_box}><h2 className={styles.section_header}>Dead Cap</h2>
                      {selectedTeamData && renderDeadCapSection(selectedTeamData)}
                      </div>
                      </div>
                    </div>
                </div>
              )}
      <div className={styles.bottom_spacer}></div>
    </div>
  );
};

export default TeamRosters;
